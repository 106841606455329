import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../src/assets/images/logo.png';
import { IoIosNotifications } from 'react-icons/io';
import { IoSearch, IoArrowBack } from 'react-icons/io5';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import { baseURL } from '../Data/api';

const Navbar = ({ onSearchResults }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [videoCount, setVideoCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const updateVideoCount = (count) => {
    setVideoCount(count);
  };

  const handleVideos = () => {
    navigate('/search');
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === '') return;

    try {
      const response = await axios.post(`${baseURL}/search`, { msisdn: searchTerm });
      onSearchResults(response.data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <nav className='bg-slate shadow-lg bg-[#6A64F1] shadow-blue-200 border-gray-200 px-2'>
      <div className='container flex justify-between items-center mx-auto'>
        <div className='flex items-center'>
          <div className='mr-1'>
            {location.pathname === '/search' && (
              <button onClick={handleBack} className='text-white'>
                <IoArrowBack size={28} color='white' />
              </button>
            )}
          </div>
          <img src={logo} alt='Bubble TV' className='w-[160px] h-[70px]' />
        </div>

        <div className='flex-grow flex justify-end items-center lg:mr-4 '>
          <div onClick={toggleSidebar} className='relative mr-4'>
            <IoIosNotifications size={35} color='white' />
            {videoCount > 0 && (
              <span className='absolute top-0 right-0 rounded-full bg-red-600 w-5 h-5 flex items-center justify-center text-white text-xs'>
                {videoCount}
              </span>
            )}
          </div>

          <div className='text-white'>
            {location.pathname === '/search' ? (
              <div className='relative'>
                <input
                  type='text'
                  name='search'
                  className='border lg:h-12 lg:w-[240px] w-[150px] shadow p-3 pl-3 rounded-full text-black'
                  placeholder='Search msisdn'
                  value={searchTerm}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                <button
                  type='button'
                  onClick={handleSearch}
                  className='absolute top-0 lg:right-8 right-1 h-full flex items-center justify-center lg:pl-3 '
                >
                  <IoSearch size={25} color='blue' />
                </button>
              </div>
            ) : (
              <button
                className='inline-flex items-center justify-center rounded-xl bg-white py-2 px-3 font-dm text-base font-medium text-black transition-transform duration-200 ease-in-out hover:scale-[1.02]'
                onClick={handleVideos}
              >
                All Videos
              </button>
            )}
          </div>
        </div>
      </div>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} updateVideoCount={updateVideoCount} />
    </nav>
  );
};

export default Navbar;
