import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { baseURL } from '../Data/api';

function AproveCard({ id, video_url, image_url, name, msisdn, onRemove,date}) {
  const [isPlaying, setIsPlaying] = useState(false);

  const notifyError = (result) => toast.error(result);
  const notifySuccess = (result) => toast.success(result);


  const declinedata = {
    id:id,
    name: name,
    video_url: video_url,
    thumbnail_url: image_url,
    status: 'DECLINE',
    msisdn: msisdn
  };

  const handleDecline = async () => {
    try {
      const response = await axios.post(`${baseURL}/decline/update`, declinedata);
      console.log(response, "declineResponse");
      notifySuccess('Video Declined Successfully');
      onRemove(id);
    } catch (error) {
      console.error(error, "error fetching data");
      notifyError('Something Went Wrong. Try Again');
    }
  };

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <>
      <div className="max-w-sm mx-auto my-2">
        <div className="bg-white  rounded-lg overflow-hidden shadow-lg ring-4 ring-black ring-opacity-40 h-full">
          <div className="relative" style={{ height: '200px',width:'260px' }}>
            {!isPlaying ? (
              <img
                className="w-full h-full object-cover"
                src={image_url}
                alt="Video Thumbnail"
              />
            ) : (
              <div style={{ width: '100%', height: '100%' }}>
                <ReactPlayer
                  url={video_url}
                  playing={isPlaying}
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            )}
            <div
              className="absolute top-0 right-0 bg-blue-500 text-white px-2 py-1 m-2 rounded-md text-sm font-medium cursor-pointer"
              onClick={handlePlay}
            >
              {isPlaying ? 'PAUSE' : 'PLAY'}
            </div>
          </div>
          <div className="p-4 bg-black/20">
          <div className='flex justify-between'>
          <h3 className="text-lg font-medium mb-2">{name}</h3>
          <h1 className="text-lg font-medium mb-2">{date.split('T')[0]}</h1>
          </div>
            
            <div className="flex items-center justify-between gap-6">
            <button
              
                className="bg-gray-900  text-white py-1 px-2 rounded"
              >
                APPROVED
              </button>
              <button
                onClick={handleDecline}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded "
              >
                DECLINE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AproveCard;
