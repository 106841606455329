import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import AproveCard from './AproveCard'; // Ensure this path is correct
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import { baseURL } from '../Data/api';


const Search = () => {
  const [results, setResults] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const[loading,setLoading]=useState(false)
  const [pageRange, setPageRange] = useState(window.innerWidth <= 768 ? 2 : 5); // Example for mobile breakpoint at 768px.

  const [currentPage, setCurrentPage] = useState(0); // start at page 0, because react-paginate is 0-indexed.
  const videosPerPage =16;
  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/search/allvideos`);
        console.log(response,"==resdata")
        setResults(response.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleRemove = (id) => {
    setResults(results.filter(result => result.id !== id));
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    
    const scrollHeight = window.innerHeight * 0.5;
    window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth' // smooth scroll
    });
};

const indexOfLastVideo = (currentPage + 1) * videosPerPage;
const indexOfFirstVideo = currentPage * videosPerPage;
const currentVideos = results.slice(indexOfFirstVideo, indexOfLastVideo);


  return (
    <>
      <Navbar onSearchResults={setResults} />
     
      <div className='lg:mt-6 mt-6 flex flex-col items-center'>
        <div className='lg:mx-4 mx-2 lg:mt-2 mt-4'>
          {results.length ?(
            <div>
              <h1 className='text-black font-semibold lg:text-xl mb-4 text-center uppercase'>Aproved videos</h1>
              <div className='lg:mb-6 w-full lg:gap-16 md:gap-8 gap-4 grid lg:grid-cols-4 bg-gray-200 rounded-lg md:grid-cols-2 grid-cols-1 lg:p-6 p-12'>
                {currentVideos.map((result, index) => (
                  <AproveCard
                    key={index}
                    id={result.id}
                    video_url={result.video_url}
                    image_url={result.thumbnail_url}
                    name={result.name}
                    msisdn={result.msisdn}
                    onRemove={handleRemove}
                    date={result.date_time}
                  />
                ))}
              </div>
            </div>
          ):(
            <div className='h-screen'>
              <h1 className='font-semibold lg:text-2xl'>No Videos Available</h1>
              </div>
          )
          }
         
        </div>
        <ReactPaginate
      className='flex justify-center items-center gap-2 mb-6 mt-2'
      previousLabel={'Previous'}
      nextLabel={'Next'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={Math.ceil(results.length / videosPerPage)}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2} // Adjust the value as needed
      onPageChange={handlePageClick}
      containerClassName={'flex justify-center list-none p-0'}
      pageClassName={'mx-1 border border-gray-300 p-2 cursor-pointer rounded'}
      activeClassName={'border-blue-500 bg-blue-500 text-white'}
      pageLinkClassName={''}
      previousClassName={'mx-1 border border-gray-300 p-2 cursor-pointer rounded'}
      previousLinkClassName={''}
      nextClassName={'mx-1 border border-gray-300 p-2 cursor-pointer rounded'}
      nextLinkClassName={''}
      // breakClassName={'mx-1 border border-gray-300 p-2 cursor-pointer rounded'}
      breakLinkClassName={''}
    />
      </div>
      <ToastContainer />
    </>
  );
};

export default Search;
