

// import React, { useEffect, useState } from "react";
// import Layout from "../components/Layout";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Cookies from 'js-cookie'
// import { useNavigate } from "react-router-dom";

// const Dashboard = () => {
//   const [file, setFile] = useState("");
//   // const [bucketName, setBucketName] = useState("");
//   // const [subfolder, setSubfolder] = useState("");
//   const [videoUrl, setVideoUrl] = useState("");
//   const [thumbnail, setThumbnail] = useState("");
//   const [name, setName] = useState("");
//   const [showVideoUrlInput, setShowVideoUrlInput] = useState(false);
//  const navigate=useNavigate()

//   useEffect(()=>{
//     const username=Cookies.get('username')
//     if(!username){
//       navigate('/login')
//     }
//   })
//   const notifyError = (message) => toast.error(message);
//   const notifySuccess = (message) => toast.success(message);

//   const handleFileSelect = (e) => {
//     const uploadedFile = e.target.files[0];
//     setFile(uploadedFile);
//   };

//   const handleThumbnailSelect = (e) => {
//     const uploadedThumbnail = e.target.files[0];
//     setThumbnail(uploadedThumbnail);
//   };


//   const handleFileUpload = async (e) => {
//     e.preventDefault();

//     if ((!file && videoUrl.trim() === "")  || name.trim() === "" || !thumbnail) {
//       notifyError("Please fill all fields");
//       return;
//     }

//     if (file) {
//       const formData = new FormData();
//       formData.append("file", file);
//       formData.append("thumbnail", thumbnail);
//       formData.append("name", name);

//       try {
//         const response = await axios.post(`http://localhost:7896/upload`, formData);
//         console.log(response.data);
//         setFile("");
//         setThumbnail("");
//         setName("");
//         notifySuccess("File uploaded successfully!");
//       } catch (error) {
//         console.error("Error uploading file:", error);
//         notifyError("Failed to upload file. Please try again later.");
//       }
//     } else if (videoUrl.trim() !== "") {
//       const formData = new FormData();
//     formData.append("videoUrl", videoUrl);  // Adjust to match server-side expectation
//     formData.append("thumbnail", thumbnail);
//     formData.append("name", name);
//       try {
//         const response = await axios.post(`http://localhost:7896/upload/url`, formData);
//         console.log(response.data);
//         setVideoUrl("");
//         setThumbnail("");
//         setName("");
//         notifySuccess("Video URL uploaded successfully!");
//       } catch (error) {
//         console.error("Error uploading video URL:", error);
//         notifyError("Failed to upload video URL. Please try again later.");
//       }
//     }
//   };

//   const toggleVideoUrlInput = () => {
//     setShowVideoUrlInput(!showVideoUrlInput);
//     setFile(null); // Clear file selection if switching to URL input
//     setThumbnail("")
//   };

//   return (
//     <Layout>
//       <ToastContainer />
//       <div className="flex items-center justify-center">
//         <div className="mx-auto w-full max-w-[550px] bg-white shadow-blue-300 rounded-lg lg:mt-8 shadow-2xl">
//           <form className="py-4 px-9">
//             <div className="mb-6 pt-4">
              
//               <button
//                 type="button"
//                 className="inline-flex items-center ml-4 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                 onClick={toggleVideoUrlInput}
//               >
//                 {showVideoUrlInput ? "Upload File Instead" : "Insert Video URL Instead"}
//               </button>
//             </div>
//             {!showVideoUrlInput ? (
//               <div className="mb-6 pt-4">
//                 <label className="mb-5 block text-xl font-semibold text-[#07074D]">
//                   Upload File
//                 </label>
//                 <div className="mb-8">
//                   <input
//                     type="file"
//                     name="file"
//                     id="file"
//                     className="sr-only"
//                     onChange={handleFileSelect}
//                   />
//                   <label
//                     htmlFor="file"
//                     className="relative flex min-h-[200px] items-center justify-center rounded-md border 
//                     border-dashed border-[#e0e0e0] p-12 text-center"
//                   >
//                     <div>
//                       <span className="mb-2 block text-xl font-semibold text-[#07074D]">
//                         Drop files here
//                       </span>
//                       <span className="mb-2 block text-base font-medium text-[#6B7280]">
//                         Or
//                       </span>
//                       <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base
//                        font-medium text-[#07074D]">
//                         Browse
//                       </span>
//                     </div>
//                   </label>
//                   {file && (
//                     <div className="mt-4">
//                       Selected File: <strong>{file.name}</strong>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             ) : (
//               <div className="mb-6 pt-4">
//                 <label className="mb-5 block text-xl font-semibold text-[#07074D]">
//                   Video URL
//                 </label>
//                 <input
//                   type="text"
//                   value={videoUrl}
//                   onChange={(e) => setVideoUrl(e.target.value)}
//                   placeholder="Enter video URL"
//                   className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
//                    focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                 />
//               </div>
//             )}
//             <div className="mb-6 pt-4">
//               <label className="mb-5 block text-xl font-semibold text-[#07074D]">
//                 Upload Thumbnail
//               </label>
//               <input
//                 type="file"
//                 onChange={handleThumbnailSelect}
//                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
//                 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//               {thumbnail && (
//                 <div className="mt-4 ">
//                   Selected Thumbnail: <strong className="w-[20px] overflow-x-auto">{thumbnail.name}</strong>
//                 </div>
//               )}
//             </div>
//             <div className="mb-6 pt-4">
//               <label className="mb-5 block text-xl font-semibold text-[#07074D]">
//                 Name
//               </label>
//               <input
//                 type="text"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
//                 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//             </div>
           
            
//             <div>
//               <button
//                 className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 
//                 px-8 text-center text-base font-semibold text-white outline-none"
//                 onClick={handleFileUpload}
//               >
//                 Upload
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </Layout>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import Loader  from '../components/Loader'
// import UserUploadedVideos from "../components/UserUplodedVideos";
import { baseURL } from "../Data/api";

const Dashboard = () => {
  const [file, setFile] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [name, setName] = useState("");
  const [showVideoUrlInput, setShowVideoUrlInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const username = Cookies.get('username');
    if (!username) {
      navigate('/login');
    } else {
      setLoggedIn(true);
    }
  }, [navigate]);

  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);

  const handleFileSelect = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const handleThumbnailSelect = (e) => {
    const uploadedThumbnail = e.target.files[0];
    setThumbnail(uploadedThumbnail);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setLoading(true);

    if ((!file && videoUrl.trim() === "") || name.trim() === "") {
      setLoading(false);
      notifyError("Please fill all fields");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);

    try {
      if (file) {
        formData.append("file", file);
        if (thumbnail) {
          formData.append("thumbnail", thumbnail);
        }
        await axios.post(`${baseURL}/upload`, formData);
      } else if (videoUrl.trim() !== "") {
        formData.append("videoUrl", videoUrl);
        if (thumbnail) {
          formData.append("thumbnail", thumbnail);
        }
        await axios.post(`${baseURL}/upload/url`, formData);
      }

      setFile("");
      setThumbnail("");
      setName("");
      setVideoUrl("");
      setLoading(false);
      notifySuccess("Upload successful!");
    } catch (error) {
      console.error("Error uploading:", error);
      setLoading(false);
      notifyError("Failed to upload. Please try again later.");
    }
  };

  const toggleVideoUrlInput = () => {
    setShowVideoUrlInput(!showVideoUrlInput);
    setFile(null); // Clear file selection if switching to URL input
    setThumbnail("");
  };

  return (
    <Layout>
      <ToastContainer />
      <div className="flex items-center justify-center">
        <div className="mx-auto w-full max-w-[550px] bg-white shadow-blue-300 rounded-lg lg:mt-8 shadow-2xl">
          <form className="py-4 px-9">
            <div className="mb-6 pt-4">
              <button
                type="button"
                className="inline-flex items-center ml-4 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={toggleVideoUrlInput}
              >
                {showVideoUrlInput ? "Upload File Instead" : "Insert Video URL Instead"}
              </button>
            </div>
            {!showVideoUrlInput ? (
              <div className="mb-6 pt-4">
                <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                  Upload File
                </label>
                <div className="mb-8">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="sr-only"
                    onChange={handleFileSelect}
                  />
                  <label
                    htmlFor="file"
                    className="relative flex min-h-[200px] items-center justify-center rounded-md border 
                    border-dashed border-[#e0e0e0] p-12 text-center"
                  >
                    <div>
                      <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                        Drop files here
                      </span>
                      <span className="mb-2 block text-base font-medium text-[#6B7280]">
                        Or
                      </span>
                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base
                       font-medium text-[#07074D]">
                        Browse
                      </span>
                    </div>
                  </label>
                  {file && (
                    <div className="mt-4 flex justify-center">
                    <strong>Selected File: </strong> <p className="w-auto overflow-x-auto">{file.name}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="mb-6 pt-4">
                <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                  Video URL
                </label>
                <input
                  type="text"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  placeholder="Enter video URL"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            )}
            <div className="mb-6 pt-4">
              <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                Upload Thumbnail
              </label>
              <input
                type="file"
                onChange={handleThumbnailSelect}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {thumbnail && (
                <div className="mt-4 flex justify-center">
                <strong>Selected Thumbnail:</strong><p className="w-auto overflow-x-auto">{thumbnail.name}</p>
                </div>
              )}
            </div>
            <div className="mb-6 pt-4">
              <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <button
                className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 
                px-8 text-center text-base font-semibold text-white outline-none"
                onClick={handleFileUpload}
              >
                {loading ? <Loader/> : (loggedIn ? "Upload" : "Login")}
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <UserUploadedVideos/> */}

    </Layout>
  );
};

export default Dashboard;
