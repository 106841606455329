// Sidebar.js
import React from 'react';
import UserUploadedVideos from '../components/UserUplodedVideos';
import { FaRegWindowClose } from "react-icons/fa";

const Sidebar = ({ isOpen, toggleSidebar, updateVideoCount }) => {
  return (
    <div
      className={`fixed z-10 top-0 right-0 w-80 h-full bg-gray-100 shadow-lg transition-transform transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <h1 className='text-center font-bold uppercase mt-6'>Notifications</h1>
      <button onClick={toggleSidebar} className="absolute top-4 right-4">
        <FaRegWindowClose color='red' size={30} />
      </button>
      <UserUploadedVideos updateVideoCount={updateVideoCount} />
    </div>
  );
};

export default Sidebar;
