
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import UserUploadedVideos from './components/UserUplodedVideos'
import Search from './components/Search'

function App() {
    return (
        <BrowserRouter>
       
                <Routes>
                    <Route path='/' element={<Dashboard />} />
                    <Route path='/login' element={<Login/>} />
                    <Route path='/notifications' element={<UserUploadedVideos/>}/>
                    <Route path='/search' element={<Search/>}/>
                    
                </Routes>
        
        </BrowserRouter>

        
        
    )
}

export default App
