import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '../components/Card';
import loadingImg from '../assets/images/bb.png'; // Import your loading image here
import { baseURL } from '../Data/api';

function UserUploadedVideos({ updateVideoCount }) {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(videos, "videos===");

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        //http://localhost:7896
        const response = await axios.get(`${baseURL}/aprove/video`);
        setVideos(response.data);
        updateVideoCount(response.data.length); // Update video count
        setLoading(false);
      } catch (err) {
        console.log(err, "error");
        setLoading(false);
      }
    };

    fetchVideos();
  }, [updateVideoCount]);

  const handleRemoveVideo = (id) => {
    setVideos(videos.filter(video => video.id !== id));
    updateVideoCount(videos.length - 1);
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <img src={loadingImg} alt='Loading...' className='w-[400px] h-[300px]' />
      </div>
    );
  }

 
  if (videos.length === 0) {
    return <p className="text-center mt-6">No New Notifications</p>;
  }


  return (
    <div className='pb-6 lg:mt-6 mt-6 overflow-y-auto max-h-[500px]'>
      <div className="flex flex-col lg:gap-4 gap-3 lg:mx-10 lg:px-6 pb-4 px-2 mx-6">
        {videos.map(video => (
          <Card
            key={video.id}
            id={video.id}
            image_url={video.thumbnail_url}
            video_url={video.video_url}
            name={video.name}
            msisdn={video.msisdn}
            channel={video.channel}
            onRemove={handleRemoveVideo}
          />
        ))}
      </div>
    </div>
  );
}

export default UserUploadedVideos;
